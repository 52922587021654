html,
body,
#root,
.react-terminal-wrapper {
  height: 100%;
}

div.react-terminal-wrapper {
  border-radius: 0;
}

a {
  color: #eee;
}

a:hover {
  opacity: .8;
}

div.react-terminal-line {
  white-space: initial;
}
